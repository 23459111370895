import {parse, stringify} from 'query-string'

export default {
    queryStringToVuetifyTableOptions(queryString){
        if(!queryString) return 

        let url = parse(queryString)
        let vuetifyOptions = {
            page: parseInt(url.page),
            itemsPerPage: parseInt(url.items)
        } 
        return vuetifyOptions
    },

    vuetifyTableOptionsToQueryString(tableOptionObject){
        if(!tableOptionObject) return

        let queryStringObject = {
            folder: tableOptionObject.folder,
            page: tableOptionObject.page,
            items: tableOptionObject.itemsPerPage
        }

        if(tableOptionObject.search){
            queryStringObject.search = tableOptionObject.search
        }

        return stringify(queryStringObject)
    }
}