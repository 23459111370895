import { fileApi } from '@/api/index.js'
import envData from '@/env.js'
export const env = envData[envData.state];
import store from '@/store'

export default ({
    list(option) {
        let url = `/file?${option}`
        return fileApi({
            method: 'get',
            url: url,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    upload(data){
        return fileApi({
            method: 'post',
            url: '/file',
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        }) 
    },
    update(data){
        return fileApi({
            method: 'patch',
            url: '/file',
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        }) 
    },
    restore(data){
        return fileApi({
            method: 'put',
            url: '/file',
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    clean(data){
        return fileApi({
            method: 'delete',
            url: '/file/cleanup',
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    delete(path) {
        return fileApi({
            method: 'delete',
            url: `/file`,
            data: path,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    emptyInSelectedFolder(path){
        return fileApi({
            method: 'delete',
            url: `/file/remove-all`,
            data: path,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})