import folderApi from '@/api/folder'

export default {
    namespaced: true,
    state: {
        all: [],
        list: [],
        tree: [],
        editObj: {}
    },
    getters: {
        all: state => state.all,
        list: state => state.list,
        tree: state => state.tree,
        editObj: state => state.editObj
    },
    mutations: {
        all(state, data){
            state.all = data
        },
        list(state, data) {
            state.list = data
        },
        tree(state, data){
            state.tree = data
        },
        editObj(state, data) {
            state.editObj = data
        },
        reset(state) {
            state.list = []
            state.editObj = {}
        }
    },
    actions: {
        async allFolders({ commit }) {
            try{
                let {data} = await folderApi.all();
                commit("all", data.payload);
            }catch(err){
                commit("all", [])
            }
        },
        async treeFolders({commit}){
            try{
                let {data} = await folderApi.tree();
                commit("tree", data.payload);
            }catch(err){
                commit("tree", [])
            }
        },
        async folders({commit}) {
            try{
                let {data} = await folderApi.list();
                commit("list", data.payload);
            }catch(err){
                commit("list", [])
            }
        }
    }
};