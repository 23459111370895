<template>
  <v-app-bar app flat class="header grey--text text--lighten-5" color="secondary">
    <v-app-bar-nav-icon class="grey--text text--lighten-5" @click.prevent="$root.LeftNav = !$root.LeftNav"></v-app-bar-nav-icon>
    <v-toolbar-title class="pl-0">
        <span>Home > {{$route.meta.nav.header}}</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <v-menu bottom offset-y>
      <template v-slot:activator="{ on }">
        <div class="setting" v-on="on">
          <span>Setting</span>
          <v-icon class="grey--text text--lighten-5">mdi-chevron-down</v-icon>
        </div>
      </template>

      <v-list>
        <v-list-item v-for="(item, i) in options" :key="i" @click="optionClicked(item)">
          <v-list-item-title>
            <v-icon>{{item.icon}}</v-icon>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="passwordPop" persistent width="400">
      <v-form ref="form">
        <v-card>
          <v-card-title class="subtitle-1 accent">Change Password</v-card-title>
          <v-card-text>
            <v-text-field
              class="mt-2"
              v-model="user.password"
              :rules="rules.password"
              hint="Please enter current password"
              label="Current Password"
            ></v-text-field>

            <v-text-field
              class="mt-2"
              v-model="user.new_password"
              :rules="rules.password"
              hint="Please enter new password"
              label="New Password"
            ></v-text-field>

            <v-text-field
              class="mt-2"
              v-model="new_password"
              :rules="rules.confirm_password"
              hint="Please confirm new password"
              label="Confirm Password"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click.prevent="cancelPassword">Cancel</v-btn>
            <v-btn color="green darken-1" dark @click.prevent="savePassword">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import User from '@/api/user'
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      breadcrumb: [],
      options: [
        {
          name: "Profile",
          title: "Update Profile",
          icon: "mdi-account"
        },
        {
          name: "ChangePassword",
          title: "Change Password",
          icon: "mdi-lock"
        },
        {
          name: "Logout",
          title: "Logout",
          icon: "mdi-logout"
        }
      ],

      user: {
        password: "",
        new_password: ""
      },
      new_password: "",
      rules: {
        password: [
          v => !!v || "Password is required",
          v => (v || "").length >= 5 || "Min 5 characaters"
        ],
        confirm_password: [
          v => !!v || "Password is required",
          v => (v || "").length >= 5 || "Min 5 characaters",
          v => v === this.user.new_password || 'The password confirmation does not match.'
        ]
      }
    };
  },
  computed: {
    passwordPop: {
      get(){
        return this.vg_passwordPop
      },
      set(val){
        this.vm_passwordPop(val)
      }
    },
    ...mapGetters({
      vg_nav: "nav/status",
      vg_login: 'login/user',
      vg_passwordPop: "login/passwordPop",
      vg_breadcrumbs: "breadcrumb/list"
    })
  },
  methods: {
    optionClicked(item) {
      if (item.name == "Logout") {
        this.vm_resetLogin();
        this.snackbar({ open: true, message: "Logout successfully." });
        this.$router.push({ name: "Login" });
      }else if(item.name=== "ChangePassword"){
        this.vm_passwordPop(true)
      } else {
        if (this.$router.history.current.name != item.name)
          this.$router.push({ name: item.name });
      }
    },
    cancelPassword(){
      this.vm_passwordPop(false)
    },
    async savePassword(){
      if(this.$refs.form.validate()){
        if(this.user.new_password!==this.new_password){
          this.snackbar({status: true, message: "Confirm password don't match with new password"})
          return
        }
        try{
          this.spinner(true)
          let {data} = await User.changePassword({
            email: this.vg_login.email,
            password: this.user.password,
            new_password: this.user.new_password
          })
          this.$refs.form.reset()
          this.snackbar({status: true, message: data.message})
          this.vm_passwordPop(false)
          this.spinner(false)
        }catch(err){
          if(err.response.data && err.response.data.message){
            this.snackbar({status: true, message: err.response.data.message})
            this.spinner(false)
          }
        }
      }
    },
    ...mapMutations({
      vm_resetLogin: "login/resetLogin",
      vm_passwordPop: "login/passwordPop"
    })
  }
};
</script>

<style scoped>
.nav-inactive {
  text-decoration: none;
  font-weight: normal;
  color: #666;
  font-size: 20px;
}
.nav-active {
  text-decoration: none;
  font-weight: normal;
  color: #333;
  font-size: 20px;
}

</style>