import { authApi } from '@/api/index.js'
import envData from '@/env.js'
export const env = envData[envData.state];
import store from '@/store'

export default ({
    login(data = { usernameOrEmail: '', password: '' }) {
        return authApi({
            method: 'post',
            url: `/user/login`,
            data: data
        })
    },
    role(){
        return authApi({
            method: 'get',
            url: `/api-user/role`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    user(userId){
        return authApi({
            method: 'get',
            url: `/user/${userId}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    update(id, data) {
        return authApi({
            method: 'patch',
            url: `/user/${id}`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    changePassword(data){
        return authApi({
            method: 'post',
            url: `/user/change-password`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})