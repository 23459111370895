<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <span>Folder</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn v-if="role.add" color="white black--text" rounded @click.prevent="createFolder"
            >Add</v-btn
          >
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_folders"
          :loading="loading"
          :search="search"
        >
          <template v-slot:item.name="{ item }">
            <span class="font-weight-bold" v-if="!item.parent">{{ item.name }}</span>
            <span v-else style="margin-left:20px;">{{ item.name }}</span>
          </template>
            
          <template v-slot:item.action="{ item }">
            <v-icon v-if="role.update" color="primary" class="ml-2 mr-1" @click.prevent="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon v-if="role.delete" color="error" @click.prevent="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import FolderApi from '@/api/folder'

export default {
  data() {
    return {
      loading: true,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id",
          sortable: false 
        },
        { text: "Name", value: "name", sortable: false  },
        { text: "Actions", value: "action", sortable: false }
      ],
      search: ''
    };
  },
  async mounted(){
    try{
      this.loading = true;
      await this.va_folders();
      this.loading = false;
    }catch(err){
      this.loading = false;
    }
  },
  computed: {
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Folder")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    ...mapGetters({
      vg_groupRole: "login/role",
      vg_folders: "folder/all"
    })
  },
  methods: {
    createFolder() {
      this.$router.push({ name: "FolderCreate" })
    },
    editItem(item) {
      this.vm_editObj(item);
      this.$router.push({ name: "FolderEdit" })
    },
    async deleteItem(item) {
      let self = this
      this.confirm('Are you sure to delete this?').then(async () => {
        try{
          self.spinner(true)
          let {data} = await FolderApi.delete({name: item.parent ? item.parent+'/'+item.name : item.name})
          this.snackbar({status: true, message: data.message})
          await this.va_folders()
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          this.snackbar({status: true, message: err.response.data.message})
        }
      })
    },
    ...mapMutations({
      vm_editObj: "folder/editObj",
      vm_queryObject: "folder/query"
    }),
    ...mapActions({
      va_folders: "folder/allFolders"
    })
  }
};
</script>