export default {
    readableFileSize(size){
        if(!size) return

        var i = Math.floor( Math.log(size) / Math.log(1024) )
        return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i]
    },

    name(path){
        if(!path) return

        return path.substring(path.lastIndexOf("/")+1, path.lastIndexOf("."))
    },

    ext(path){
        if(!path) return 

        return path.substring(path.lastIndexOf(".")+1)
    }

}