<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Create Folder</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">
                  <v-select
                    v-model="form.parent"
                    :items="vg_folders"
                    item-text="name"
                    label="Parent"
                  ></v-select>
                  
                  <v-text-field
                    class="mt-2"
                    v-model="form.name"
                    :rules="rules.name"
                    hint="Please enter name"
                    label="Name*"
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FileApi from '@/api/folder'
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        parent: "",
        name: ""
      },
      rules: {
        name: [v => !!v || "Name is required"]
      }
    };
  },
  async mounted(){
    try{
      this.loading = true;
      await this.va_folders();
      this.loading = false;
    }catch(err){
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters({
      vg_folders: "folder/list"
    })
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await FileApi.create(this.form)
          this.snackbar({status: true, message: data.message})
          this.spinner(false)
          this.$router.push({name: 'Folder'})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Folder" });
    },
    ...mapActions({
      va_folders: "folder/folders"
    })
  }
};
</script>