import file from '@/views/file';

export default [
    {
        name: 'File',
        path: '/file-manager',
        component: file,
        meta: {
            nav: {
                addToNav: true,
                navText: 'File',
                header: 'File Manager',
                navIcon: 'mdi-file',
            }
        }
    }
]