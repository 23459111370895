import { fileApi } from '@/api/index.js'
import envData from '@/env.js'
export const env = envData[envData.state];
import store from '@/store'

export default ({
    all() {
        return fileApi({
            method: 'get',
            url: `/folder/all`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    tree() {
        return fileApi({
            method: 'get',
            url: `/folder/tree`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    list() {
        return fileApi({
            method: 'get',
            url: `/folder`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    files(data) {
        return fileApi({
            method: 'post',
            url: `/folder/files`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    create(data) {
        return fileApi({
            method: 'post',
            url: `/folder`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    update(data) {
        return fileApi({
            method: 'patch',
            url: `/folder`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    delete(path) {
        return fileApi({
            method: 'delete',
            url: `/folder`,
            data: path,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    trash(){
        return fileApi({
            method: 'get',
            url: `/folder/trash`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})