import FileApi from '@/api/file'

export default {
    namespaced: true,
    state: {
        list: [],
        uploadPop: false,
        editPop: false,
        editObj: {},
        selected: '',
        query: {
            folder: '',
            search: '',
            itemsPerPage: 15,
            page: 1
        },
    },
    getters: {
        list: state => state.list,
        uploadPop: state => state.uploadPop,
        editPop: state => state.editPop,
        editObj: state => state.editObj,
        selected: state => state.selected,
        query: state => state.query
    },
    mutations: {
        list(state, data) {
            state.list = data
        },
        uploadPop(state, data) {
            state.uploadPop = data
        },
        editPop(state, data){
            state.editPop = data
        },
        editObj(state, data) {
            state.editObj = data
        },
        selected(state, data){
            state.selected = data
        },
        query(state, data){
            state.query = data
        },
        reset(state) {
            state.list = []
            state.uploadPop = false
            state.editObj = {},
            state.selected = ''
            state.query = {
                folder: '',
                search: '',
                itemsPerPage: 15,
                page: 1
            }
        }
    },
    actions: {
        async list({ commit }, option) {
            try{
                let {data} = await FileApi.list(option);
                commit("list", data.payload);
            }catch(err){
                commit("list", [])
            }
        }
    }
};