<template>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="subtitle-1 accent">Edit File</v-card-title>

        <v-card-text>
            <v-select
                v-model="form.path"
                :rules="rules.path"
                :items="vg_folders"
                item-text="path"
                label="Move To"
                ></v-select>

            <v-text-field
                class="mt-2"
                v-model="form.file"
                :rules="rules.name"
                hint="Please enter name"
                label="Rename to*"
                ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click.prevent="dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" text @click.prevent="editFile">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import FileApi from '@/api/file'
import VuetifyTable from '@/helper/table'
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    data(){
        return {
            form: {
                path: '',
                file: ''
            },
            rules: {
                path: [v => !!v || "File path is required"],
                name: [v => !!v || "File name is required"]
            }
        }
    },
    watch: {
        async vg_editPop(val){
            if(val){
                await this.va_folders()
                this.form.path = this.vg_editObj.path
                this.form.file = this.vg_editObj.file
            }
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_editPop
            },
            set(val) {
                this.vm_editPop(val)
            }
        },
        ...mapGetters({
            vg_folders: 'folder/all',
            vg_editPop: 'file/editPop',
            vg_editObj: 'file/editObj',
            vg_queryObject: "file/query",
        })
    },
    methods: {
        optionsToQueryString(options){
        return VuetifyTable.vuetifyTableOptionsToQueryString(options)
        },
        async editFile(){
            try{
                this.spinner(true)
                let param = {
                    file: `${this.vg_editObj.path}/${this.vg_editObj.file}`,
                    new: `${this.form.path}/${this.form.file}`
                }
                let {data} = await FileApi.update(param)
                this.snackbar({status: true, message: data.message})
                await this.va_files(this.optionsToQueryString(this.vg_queryObject))
                this.spinner(false)
                this.vm_editPop(false)
            }catch(err){
                this.snackbar({status: true, message: err.response.data.message})
                this.spinner(false)
            }
        },
        ...mapMutations({
            vm_editPop: 'file/editPop'
        }),
        ...mapActions({
            va_folders: "folder/allFolders",
            va_files: "file/list"
        })
    }
}
</script>
