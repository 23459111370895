import Folder from '@/views/folder';
import FolderCreate from '@/views/folder/create'
import FolderEdit from '@/views/folder/edit'
// import FolderTrash from '@/views/folder/trash'

export default [
    {
        name: 'Folder',
        path: '/folder',
        component: Folder,
        meta: {
            nav: {
                addToNav: true,
                navText: 'Folder',
                header: 'Folder',
                navIcon: 'mdi-folder',
            }
        }
    },
    {
        name: 'FolderCreate',
        path: '/folder/create',
        component: FolderCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Folder > Create',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'FolderEdit',
        path: '/folder/edit',
        component: FolderEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Folder > Edit',
                navIcon: 'mdi-api',
            }
        }
    },
    // {
    //     name: 'FolderTrash',
    //     path: '/folder/trash',
    //     component: FolderTrash,
    //     meta: {
    //         nav: {
    //             addToNav: false,
    //             navText: '',
    //             header: 'Folder > Trash',
    //             navIcon: 'mdi-api',
    //         }
    //     }
    // }
]