<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title>
          File Manager
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0">
        <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-card>
                  <v-card-title>
                    <span class="ml-5">Folder</span>
                  </v-card-title>
                  <v-card-text v-if="vg_folders.length===0">No folder found.</v-card-text>
                  <v-card-text v-else style="max-height:500px; overflow: auto;">
                    <v-treeview
                      open-all
                      activatable
                      :items="vg_folders"
                      @update:active="selected"
                    > 
                      <template v-slot:prepend="{ open }">
                        <v-icon >
                          {{ open ? 'mdi-folder-open' : 'mdi-folder'  }}
                        </v-icon>
                      </template>
                    </v-treeview>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="9">
                <v-card>
                  <v-card-title>
                      <span v-if="vg_selected">{{vg_selected}}</span>
                      <span v-else>Contents</span>
                      <v-spacer />
                      <v-text-field
                        v-model="options.search"
                        clearable
                        flat
                        solo
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        label="Search"
                      ></v-text-field>
                      <v-spacer />
                      <v-btn  v-if="role.erase" class="mr-3" color="primary" :disabled="clean>0 || syncs>0" @click.prevent="cleanFolder">
                          <v-icon>mdi-folder-alert</v-icon>
                          <span v-if="clean>0">Clean {{clean}}</span>
                          <span v-else>Clean</span>
                      </v-btn>
                      <v-btn  v-if="role.restore" class="mr-3" color="primary" :disabled="clean>0 || syncs>0" @click.prevent="searchFolder">
                          <v-icon>mdi-folder-search</v-icon>
                          <span v-if="syncs>0">Sync {{syncs}}</span>
                          <span v-else>Sync</span>
                      </v-btn>
                      <v-btn  v-if="role.add" color="primary" @click.prevent="uploadPop">
                          <v-icon>mdi-upload</v-icon>
                          <span>Upload</span>
                      </v-btn>
                  </v-card-title>                  
                  <v-card-text>
                    <loading v-if="loading" />
                    <div v-else-if="vg_files.length===0">No file found.</div>
                    <div v-else>
                      <v-data-iterator
                        :loading="loading"
                        :items="vg_files.rows"
                        :server-items-length="vg_files.count"
                        :options.sync="options"
                        :items-per-page="options.itemsPerPage"
                      >
                        <template v-slot:default="{ items }">
                            <v-row>
                                <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in items" :key="index">
                                    <v-card>
                                        <v-img v-if="isImage(item.ext)" :src="getImage(item)"  height="120" />
                                        <v-icon v-else size="120" class="ma-0 pa-0">mdi-file</v-icon>
                                        <v-card-text class="px-3 py-0">
                                            <div class="text-truncate">{{item.file}}</div>
                                            <div class="text-truncate">{{calculateSize(item.size)}}</div>
                                            <div class="text-truncate" v-if="item.width">{{item.width}}x{{item.height}}</div>
                                            <div v-else>-</div>
                                        </v-card-text>
                                        <v-card-actions class="pt-0">
                                            <v-btn v-if="role.update" class="primary" small @click.prevent="editItem(item)">Edit</v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn  v-if="role.delete" class="error" small @click.prevent="deleteItem(item)">Delete</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>
                      </v-data-iterator>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
      </v-card-text>
    </v-card>
    <edit />
    <upload />
  </v-container>
</template>

<script>
import _ from 'lodash'
import envData from '@/env.js'
export const env = envData[envData.state]
import VuetifyTable from '@/helper/table'
import fileHelper from '@/helper/file'

import FileApi from '@/api/file'
import FolderApi from '@/api/folder'

import Edit from '@/views/file/edit'
import Upload from '@/views/file/upload'
import Loading from '@/components/Loading'
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    Loading,
    Edit,
    Upload
  },
  data() {
    return {
      syncs: 0,
      clean: 0,
      loading: false,
      imgs: ['jpg', 'jpeg', 'png', 'gif', 'webp']
    };
  },
  async mounted(){
     try{
      this.spinner(true)
      await this.va_folders();
      this.spinner(false)
    }catch(err){
      this.spinner(false)
    }
  },
  watch: {
    'vg_queryObject.itemsPerPage'(){
        this.load()
    },
    'vg_queryObject.folder'(){
        this.load()
    },
    'vg_queryObject.page'(){
        this.load()
    },
    'vg_queryObject.search': {
      handler: _.debounce(async function() {
        this.options.page = 1
        this.load()
      }, 900),
    },
  },
  computed: {
    options: {
      get(){
        return this.vg_queryObject
      },
      set(options){
        this.vm_queryObject(options)
      }
    },
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="File")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    ...mapGetters({
      vg_groupRole: "login/role",
      vg_folders: "folder/tree",
      vg_queryObject: "file/query",
      vg_files: "file/list",
      vg_selected: 'file/selected'
    })
  },
  methods: {
    async load(){
      try{
          this.loading = true;
          await this.va_files(this.optionsToQueryString(this.vg_queryObject))
          this.loading = false;
        }catch(err){
          this.loading = false;
        }
    },
    queryStringToOptions(queryString){
      return VuetifyTable.queryStringToVuetifyTableOptions(queryString)
    },
    optionsToQueryString(options){
      return VuetifyTable.vuetifyTableOptionsToQueryString(options)
    },
    getImage(item){
      return env.file_path+item.path+'/'+item.file
    },
    isImage(ext){
      return this.imgs.includes(ext)
    },
    calculateSize(size){
      return fileHelper.readableFileSize(size)
    },
    async selected(item){
      if(item && item[0]){
        this.vm_selected(item[0])
        this.options.page = 1
        this.vm_queryObject({...this.vg_queryObject, folder: item[0]})
      }
    },
    editItem(item) {
      this.vm_editObj(item)
      this.vm_editPop(true)
    },
    uploadPop(){
      this.vm_uploadPop(true)
    },
    async cleanFolder(){
      let {data} = await FolderApi.files({folder: this.vg_selected})
      if(data.payload && data.payload.length>0){
          // have physical file
          this.clean = data.payload.length
          for(const ind in data.payload){
            try{
              await FileApi.clean({file: this.vg_selected+'/'+data.payload[ind]})
              this.clean--
            }catch(err){
              console.log(err)
              this.clean--
            }
          }
          this.options.page = 1
          await this.va_files(this.optionsToQueryString(this.vg_queryObject))
      }else{
        // don't have physical file
        try{
          await FileApi.emptyInSelectedFolder({file: this.vg_selected})
        }catch(err){
          console.log(err)
        }
      }
    },
    async searchFolder(){
      let {data} = await FolderApi.files({folder: this.vg_selected})
      
      if(data.payload && data.payload.length>0){
        // empty all data from selected folder
        await FileApi.emptyInSelectedFolder({file: this.vg_selected})

        this.syncs = data.payload.length
        for(const ind in data.payload){
            if(data.payload[ind].split('.').pop().length>2 && data.payload[ind].split('.').length>1){
              try{
                await FileApi.restore({file: this.vg_selected+'/'+data.payload[ind]})
                this.syncs--
              }catch(err){
                this.syncs--
              }
            }
        }
        await this.va_files(this.optionsToQueryString(this.vg_queryObject))
      }
    },
    async deleteItem(item) {
      let self = this
      this.confirm('Are you sure to delete this?').then(async () => {
        try{
          self.spinner(true)
          let {data} = await FileApi.delete({file: item.path+'/'+item.file})
          this.snackbar({status: true, message: data.message})
          await this.va_files(this.optionsToQueryString(this.vg_queryObject))
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          console.log(err)
        }
      })
    },
    ...mapMutations({
      vm_editObj: "file/editObj",
      vm_queryObject: "file/query",
      vm_editPop: "file/editPop",
      vm_selected: "file/selected",
      vm_uploadPop: "file/uploadPop"
    }),
    ...mapActions({
      va_folders: "folder/treeFolders",
      va_files: "file/list"
    })
  }
}
</script>