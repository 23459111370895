module.exports = {
    state: 'production',
    development: {
        app_key: process.env.VUE_APP_KEY,
        api_token: process.env.VUE_APP_API_TOKEN,
        auth_url: process.env.VUE_APP_AUTH_URL,
        file_url: process.env.VUE_APP_FILE_URL,
        file_path: process.env.VUE_APP_FILE_PATH
    },
    test: {
        app_key: process.env.VUE_APP_KEY,
        api_token: process.env.VUE_APP_API_TOKEN,
        auth_url: process.env.VUE_APP_AUTH_URL,
        file_url: process.env.VUE_APP_FILE_URL,
        file_path: process.env.VUE_APP_FILE_PATH
    },
    production: {
        app_key: process.env.VUE_APP_KEY,
        api_token: process.env.VUE_APP_API_TOKEN,
        auth_url: process.env.VUE_APP_AUTH_URL,
        file_url: process.env.VUE_APP_FILE_URL,
        file_path: process.env.VUE_APP_FILE_PATH
    }
}